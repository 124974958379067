import { handleActions } from 'redux-actions';
import * as C from './constants';

export const initialState = {
  exampleData: [],
  lastFetched: null,
  auth: {},
  managerCurrentEmployees: null,
  managerPreviousEmployees: null,
  managers: null,
  hasNoManager: null,
  postCurrentStaff: null,
  postAssignTemporaryManager: null,
  report: null,
};

const getExampleDataSuccess = (state, action) => ({
  ...state,
  exampleData: action.payload,
  lastFetched: Date.now(),
});

const getAuthSuccess = (state, action) => ({
  ...state,
  auth: action.payload,
});

const getManagerCurrentEmployeesSuccess = (state, action) => ({
  ...state,
  managerCurrentEmployees: action.payload,
});

const getManagerPreviousEmployeesSuccess = (state, action) => ({
  ...state,
  managerPreviousEmployees: action.payload,
});

const getManagersSuccess = (state, action) => ({
  ...state,
  managers: action.payload,
});

const getHasNoManagerSuccess = (state, action) => ({
  ...state,
  hasNoManager: action.payload,
});

const postCurrentStaffSuccess = (state, action) => ({
  ...state,
  postCurrentStaff: action.payload,
});

const postAssignTemporaryManagerSuccess = (state, action) => ({
  ...state,
  postAssignTemporaryManager: action.payload,
});

const getReportSuccess = (state, action) => ({
  ...state,
  report: action.payload,
});

export default handleActions({
  [C.GET_EXAMPLE_DATA_SUCCESS]: getExampleDataSuccess,
  [C.GET_AUTH_SUCCESS]: getAuthSuccess,
  [C.GET_MANAGER_CURRENT_EMPLOYEES_SUCCESS]: getManagerCurrentEmployeesSuccess,
  [C.GET_MANAGER_PREVIOUS_EMPLOYEES_SUCCESS]: getManagerPreviousEmployeesSuccess,
  [C.GET_MANAGERS_SUCCESS]: getManagersSuccess,
  [C.GET_HAS_NO_MANAGER_SUCCESS]: getHasNoManagerSuccess,
  [C.POST_CURRENT_STAFF_SUCCESS]: postCurrentStaffSuccess,
  [C.POST_ASSIGN_TEMPORARY_MANAGER_SUCCESS]: postAssignTemporaryManagerSuccess,
  [C.GET_REPORT_SUCCESS]: getReportSuccess,
}, initialState);
