import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore, serviceWorker } from 'state-template';
import { BrowserRouter as Router } from 'react-router-dom';

// import 'bootstrap/dist/css/bootstrap.min.css';
import 'state-template/dist/style/core/css/cagov.core.min.css';
import 'state-template/dist/style/core/css/colorscheme-oceanside.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'style/style.scss';
import App from 'containers/App';
import reducer from 'redux/reducer';
import saga from 'redux/saga';

const reducers = {
  reducer,
};

const sagas = [
  saga,
];

const store = configureStore({ reducers, sagas });

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root'),
);

serviceWorker.register();
