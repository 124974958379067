import React, { useEffect } from 'react';
import Header from 'components/Header/Header';
import { Routing, Footer, azureAuth2 } from 'state-template';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from 'redux/selectors';
import * as actions from 'redux/actions';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';

const AppMsal = (props) => {
  const {
    authSelector, initApp, getAuthDispatch,
  } = props;

  const { MSAL, AUTHENTICATION_METHOD, AUTHENTICATION_TYPES, API_SCOPES, AUTH_PAGES_HR, AUTH_PAGES_ADMIN, AUTH_PAGES_MANAGER, FOOTER_LINKS, CONTACT_LINK, ROLES } = window.config;

  const isMsalAuthenticated = useIsAuthenticated();
  const msalInstance = azureAuth2.useMsalInstance();
  const history = useHistory();

  useEffect(() => {
    if (!isMsalAuthenticated) {
      getAuthDispatch({});
    } else {
      const authenticationMode = AUTHENTICATION_TYPES.MSAL;
      const apiScopes = API_SCOPES;
      const msalConfig = {
        msalInstance,
        msal: MSAL,
      };
      initApp({ authenticationMode, msalConfig, apiScopes });
    }
    // eslint-disable-next-line
    }, [isMsalAuthenticated]);

  const calculateAuthLocation = () => {
    let authLocation = null;
    let notFound = true;

    if (!AUTH_PAGES_HR.find((hrPages) => hrPages === history.location.pathname || AUTH_PAGES_MANAGER.find((mgrPages) => mgrPages === history.location.pathname
      || AUTH_PAGES_ADMIN.find((adminPage) => adminPage === history.location.pathname)))) {
      return '/not-found';
    }

    // There is a race condition where the roles are not yet loaded from the reducer.  Therefore, we just return the current location until the roles are loaded.
    if (!authSelector.roles) {
      return history.location.pathname;
    }

    if (authSelector.roles) {
      if (authSelector.roles.includes(ROLES.HR)) {
        if (AUTH_PAGES_HR.find((page) => page === history.location.pathname)) {
          notFound = false;
        }
      }

      if (authSelector.roles.includes(ROLES.MANAGER)) {
        if (AUTH_PAGES_MANAGER.find((page) => page === history.location.pathname)) {
          notFound = false;
        }
      }
      if (authSelector.roles.includes(ROLES.SYSTEM_ADMINISTRATOR)) {
        if (!AUTH_PAGES_ADMIN.find((page) => page === history.location.pathname)) {
          notFound = false;
        }
      }
    }

    if (notFound) {
      authLocation = '/not-found';
    } else {
      authLocation = history.location.pathname;
    }

    return authLocation;
  };

  return (
    <>
      <Header
        authenticationMode={AUTHENTICATION_METHOD}
        isAuthenticated={isMsalAuthenticated}
      />
      <AuthenticatedTemplate>
        <Routing routes={Routes(authSelector)} redirect={calculateAuthLocation()} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routing routes={Routes(authSelector)} redirect={'/not-found'} />
      </UnauthenticatedTemplate>
      <Footer footerLinks={FOOTER_LINKS} contactLink={CONTACT_LINK} />
    </>
  );
};

AppMsal.propTypes = {
  /** App initialization */
  initApp: T.func.isRequired,
  /** user authentication information */
  authSelector: T.object,
  getAuthDispatch: T.func.isRequired,
};

AppMsal.defaultProps = {
  authSelector: {},
};

export const mapStateToProps = createStructuredSelector({
  authSelector: selectors.getAuth(),
});

export const mapDispatchToProps = (dispatch) => ({
  initApp: (msal) => dispatch(actions.initApp(msal)),
  getAuthDispatch: (input) => dispatch(actions.getAuth(input)),
  login: () => dispatch(actions.loginUser()),
  logout: () => dispatch(actions.logoutUser()),
});

const usingRedux = connect(mapStateToProps, mapDispatchToProps)(AppMsal);
export default usingRedux;
