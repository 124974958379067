import React, { useEffect } from 'react';
import Header from 'components/Header/Header';
import { Routing, Footer } from 'state-template';
import T from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import { Routes } from '../../../routes';

const AppNoAuth = (props) => {
  const {
    initApp,
  } = props;

  const { AUTHENTICATION_METHOD, AUTHENTICATION_TYPES, FOOTER_LINKS, CONTACT_LINK } = window.config;

  useEffect(() => {
    const authenticationMode = AUTHENTICATION_TYPES.NONE;
    initApp({ authenticationMode });
  }, [AUTHENTICATION_TYPES.NONE, initApp]);

  return (
    <>
      <Header authenticationMode={AUTHENTICATION_METHOD} />
      <Routing routes={Routes()} redirect={'/not-found'} />
      <Footer footerLinks={FOOTER_LINKS} contactLink={CONTACT_LINK} />
    </>
  );
};

AppNoAuth.propTypes = {
  /** App initialization */
  initApp: T.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  initApp: () => dispatch(actions.initApp()),
});

const usingRedux = connect(null, mapDispatchToProps)(AppNoAuth);
export default usingRedux;
