export const GET_EXAMPLE_DATA = 'Home/GET_EXAMPLE_DATA';
export const GET_EXAMPLE_DATA_SUCCESS = 'Home/GET_EXAMPLE_DATA_SUCCESS';

export const GET_MANAGER_EMPLOYEES = 'GET_MANAGER_EMPLOYEES';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';

export const GET_AUTH = 'GET_AUTH';
export const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';

export const POST_CURRENT_STAFF = 'POST_CURRENT_STAFF';
export const POST_CURRENT_STAFF_SUCCESS = 'POST_CURRENT_STAFF_SUCCESS';

export const POST_ASSIGN_TEMPORARY_MANAGER = 'POST_ASSIGN_TEMPORARY_MANAGER';
export const POST_ASSIGN_TEMPORARY_MANAGER_SUCCESS = 'POST_ASSIGN_TEMPORARY_MANAGER_SUCCESS';

export const INIT_APP = 'INIT_APP';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const GET_USER_PROFILE_PICTURE = 'GET_USER_PROFILE_PICTURE';
export const GET_USER_PROFILE_PICTURE_SUCCESS = 'GET_USER_PROFILE_PICTURE_SUCCESS';

export const GET_MANAGER_CURRENT_EMPLOYEES = 'GET_MANAGER_CURRENT_EMPLOYEES';
export const GET_MANAGER_CURRENT_EMPLOYEES_SUCCESS = 'GET_MANAGER_CURRENT_EMPLOYEES_SUCCESS';

export const CLEAR_MANAGER_CURRENT_EMPLOYEES = 'CLEAR_MANAGER_CURRENT_EMPLOYEES';

export const GET_MANAGER_PREVIOUS_EMPLOYEES = 'GET_MANAGER_PREVIOUS_EMPLOYEES';
export const GET_MANAGER_PREVIOUS_EMPLOYEES_SUCCESS = 'GET_MANAGER_PREVIOUS_EMPLOYEES_SUCCESS';

export const CLEAR_MANAGER_PREVIOUS_EMPLOYEES = 'CLEAR_MANAGER_PREVIOUS_EMPLOYEES';

export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';

export const CLEAR_MANAGERS = 'CLEAR_MANAGERS';

export const GET_HAS_NO_MANAGER = 'GET_HAS_NO_MANAGER';
export const GET_HAS_NO_MANAGER_SUCCESS = 'GET_HAS_NO_MANAGER_SUCCESS';

export const CLEAR_HAS_NO_MANAGER = 'CLEAR_HAS_NO_MANAGER';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';

export const CLEAR_REPORT = 'CLEAR_REPORT';
