import React, { useEffect } from 'react';
import { Form as ReduxForm, FieldReactSelect, Button, azureAuth2 } from 'state-template';
import { withRouter, Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import T from 'prop-types';
import { reset, getFormValues } from 'redux-form';
import Oops from '../../../components/Oops';
import schema from './schema';
import SuccessDialog from '../../../components/SuccessDialog/SuccessDialog';
import ErrorDialog from '../../../components/ErrorDialog';

export const AdminContent = (props) => {
  const {
    formValuesSelector,
    managersDispatch,
    managersSelector,
    hasNoManagerDispatch,
    hasNoManagerSelector,
    refresh,
    resetForm,
    postAssignTemporaryManagerDispatch,
    postAssignTemporaryManagerSuccessSelector,
    resetPostTemporaryManagerRequestDispatch,
    clearHasNoManagerDispatch,
    clearManagersDispatch,
  } = props;

  const managerList = [];
  const employeeList = [];

  const msalInstance = azureAuth2.useMsalInstance();
  // Called when component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
    managersDispatch({ msalInstance });
    hasNoManagerDispatch({ msalInstance });
    // eslint-disable-next-line
  }, []);

  // Forces the page to rerender as refresh is Math.random().  Fixes the issue if page already loaded, but
  // user clicks on icon in header.  If no icon in header, remove this useEffect.
  useEffect(() => {
    clearHasNoManagerDispatch();
    clearManagersDispatch();
    managersDispatch({ msalInstance });
    hasNoManagerDispatch({ msalInstance });
    // eslint-disable-next-line
  }, [refresh]);

  const onSubmit = () => {
    const record = {
      authorizationID: formValuesSelector.employee.value,
      managerID: formValuesSelector.manager.value,
    };

    postAssignTemporaryManagerDispatch({ msalInstance, record });
  };

  const resetPostRequest = () => {
    resetPostTemporaryManagerRequestDispatch();
    resetForm();
    hasNoManagerDispatch({ msalInstance });
  };

  const displayResult = () => {
    if (postAssignTemporaryManagerSuccessSelector !== null) {
      if (postAssignTemporaryManagerSuccessSelector) {
        if (postAssignTemporaryManagerSuccessSelector === 'SUCCESS') {
          const success = {};
          success.title = 'Success';
          success.status = 'Successful Submission';
          success.statusText = 'The request has been successfully submitted';
          success.icon = 'check-fill';
          success.iconColor = 'green';
          return (
            <SuccessDialog
              success={success}
              handleReset={resetPostRequest}
            />
          );
        }
      }
      if (postAssignTemporaryManagerSuccessSelector.status !== 204) {
        const err = [];
        if (postAssignTemporaryManagerSuccessSelector.errorMessage) {
          const keys = Object.keys(postAssignTemporaryManagerSuccessSelector.errorMessage.errors);

          keys.forEach((key) => {
            err.push(`${postAssignTemporaryManagerSuccessSelector.errorMessage.errors[key]}`);
          });
        }
        const error = {};
        error.title = 'Unable to Submit Request';
        error.status = 'Unable to submit request';
        error.statusText = err;
        error.icon = 'warning-triangle';
        error.iconColor = '#b8291f';

        return (
          <ErrorDialog
            error={error}
            resetData={resetPostRequest}
          />
        );
      }
    }

    return null;
  };

  if (managersSelector && managersSelector.length > 0) {
    managersSelector.forEach((manager) => {
      let entry = {};
      entry = {
        value: manager.id,
        label: `${manager.firstName} ${manager.lastName}`,
      };
      managerList.push(entry);
    });
  }

  if (hasNoManagerSelector && hasNoManagerSelector.length > 0) {
    hasNoManagerSelector.forEach((employee) => {
      let entry = {};
      entry = {
        value: employee.authorizationID,
        label: employee.fullName,
      };
      employeeList.push(entry);
    });
  }

  if (!managersSelector || !hasNoManagerSelector) {
    return (
      <div className={'spinner'} />
    );
  }

  if ((managersSelector && managersSelector.status && managersSelector.status === 'loading')
    || (hasNoManagerSelector && hasNoManagerSelector.status && hasNoManagerSelector.status === 'loading')) {
    return (
      <div className={'spinner'} />
    );
  }

  if ((managersSelector && managersSelector.status >= 300) || (hasNoManagerSelector && hasNoManagerSelector.status >= 300)) {
    return (
      <Oops />
    );
  }

  return (
    <ReduxForm
      form={'assignManager'}
      onSubmit={onSubmit}
    >
      <Prompt
        when={formValuesSelector !== undefined && formValuesSelector !== null}
        message={'You are currently editing the request. Your changes will be lost if you leave.'}
      />

      {displayResult()}
      <div>
        <h2>Assign A Temporary Manager </h2>
        <p>The following employees do not currently have an assigned manager.  Fill out the form to assign a temporary manager.</p>
        <FieldReactSelect
          {...schema.employee}
          options={employeeList}
        />
        <FieldReactSelect
          {...schema.manager}
          options={managerList}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            className={'btn-outline-primary'}
            type={'submit'}
          >
            Submit
          </Button>
        </div>
      </div>
    </ReduxForm>
  );
};

AdminContent.propTypes = {
  formValuesSelector: T.object,
  resetForm: T.func.isRequired,
  managersDispatch: T.func.isRequired,
  managersSelector: T.oneOfType([
    T.array,
    T.object,
  ]),
  hasNoManagerDispatch: T.func.isRequired,
  hasNoManagerSelector: T.oneOfType([
    T.array,
    T.object,
  ]),
  postAssignTemporaryManagerDispatch: T.func.isRequired,
  postAssignTemporaryManagerSuccessSelector: T.string,
  refresh: T.number.isRequired,
  resetPostTemporaryManagerRequestDispatch: T.func.isRequired,
  clearHasNoManagerDispatch: T.func.isRequired,
  clearManagersDispatch: T.func.isRequired,
};

AdminContent.defaultProps = {
  formValuesSelector: null,
  managersSelector: [],
  hasNoManagerSelector: [],
  postAssignTemporaryManagerSuccessSelector: null,
};

// Name all selector variables ending with "Selector".  This makes it easier to distingush between
// selector and other variables
export const mapStateToProps = createStructuredSelector({
  formValuesSelector: getFormValues('assignManager'),
  managersSelector: selectors.getManagers(),
  hasNoManagerSelector: selectors.getHasNoManager(),
  postAssignTemporaryManagerSuccessSelector: selectors.postAssignTemporaryManager(),
});

// Name all dispatch variables ending with "Dispatch".  This makes it easier to distingush between
// dispatch and other variables
export const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(reset('assignManager')),
  managersDispatch: (values) => dispatch(actions.getManagers(values)),
  hasNoManagerDispatch: (values) => dispatch(actions.getHasNoManager(values)),
  postAssignTemporaryManagerDispatch: (values) => dispatch(actions.postAssignTemporaryManager(values)),
  resetPostTemporaryManagerRequestDispatch: () => dispatch(actions.postAssignTemporaryManagerSuccess(null)),
  clearHasNoManagerDispatch: () => dispatch(actions.clearHasNoManager()),
  clearManagersDispatch: () => dispatch(actions.clearManagers()),
});

const usingRouter = withRouter(AdminContent);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
