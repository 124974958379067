import { createAction } from 'redux-actions';

import * as C from './constants';

export const getExampleData = createAction(C.GET_EXAMPLE_DATA);
export const getExampleDataSuccess = createAction(C.GET_EXAMPLE_DATA_SUCCESS);

export const getManagerEmployees = createAction(C.GET_MANAGER_EMPLOYEES);

export const getAuth = createAction(C.GET_AUTH);
export const getAuthSuccess = createAction(C.GET_AUTH_SUCCESS);

export const loginUser = createAction(C.LOGIN_USER);
export const logoutUser = createAction(C.LOGOUT_USER);

export const getUserProfile = createAction(C.GET_USER_PROFILE);
export const getUserProfileSuccess = createAction(C.GET_USER_PROFILE_SUCCESS);

export const initApp = createAction(C.INIT_APP);

export const setAccessToken = createAction(C.SET_ACCESS_TOKEN);

export const getUserProfilePicture = createAction(C.GET_USER_PROFILE_PICTURE);
export const getUserProfilePictureSuccess = createAction(C.GET_USER_PROFILE_PICTURE_SUCCESS);

export const getManagerCurrentEmployees = createAction(C.GET_MANAGER_CURRENT_EMPLOYEES);
export const getManagerCurrentEmployeesSuccess = createAction(C.GET_MANAGER_CURRENT_EMPLOYEES_SUCCESS);

export const clearManagerCurrentEmployees = createAction(C.CLEAR_MANAGER_CURRENT_EMPLOYEES);

export const getManagerPreviousEmployees = createAction(C.GET_MANAGER_PREVIOUS_EMPLOYEES);
export const getManagerPreviousEmployeesSuccess = createAction(C.GET_MANAGER_PREVIOUS_EMPLOYEES_SUCCESS);

export const clearManagerPreviousEmployees = createAction(C.CLEAR_MANAGER_PREVIOUS_EMPLOYEES);

export const getManagers = createAction(C.GET_MANAGERS);
export const getManagersSuccess = createAction(C.GET_MANAGERS_SUCCESS);

export const clearManagers = createAction(C.CLEAR_MANAGERS);

export const getHasNoManager = createAction(C.GET_HAS_NO_MANAGER);
export const getHasNoManagerSuccess = createAction(C.GET_HAS_NO_MANAGER_SUCCESS);

export const clearHasNoManager = createAction(C.CLEAR_HAS_NO_MANAGER);

export const postCurrentStaff = createAction(C.POST_CURRENT_STAFF);
export const postCurrentStaffSuccess = createAction(C.POST_CURRENT_STAFF_SUCCESS);

export const postAssignTemporaryManager = createAction(C.POST_ASSIGN_TEMPORARY_MANAGER);
export const postAssignTemporaryManagerSuccess = createAction(C.POST_ASSIGN_TEMPORARY_MANAGER_SUCCESS);

export const getReport = createAction(C.GET_REPORT);
export const getReportSuccess = createAction(C.GET_REPORT_SUCCESS);

export const clearReport = createAction(C.CLEAR_REPORT);
