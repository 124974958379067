import React, { useEffect } from 'react';
import { Table, Form as ReduxForm, azureAuth2 } from 'state-template';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import T from 'prop-types';

import formatRecords from '../../../utils/formatRecords';
import Oops from '../../../components/Oops';

export const PreviousStaffContent = (props) => {
  const {
    refresh,
    managerEmployeesDispatch,
    managerPreviousEmployeesSelector,
    clearManagerPreviousEmployeesDispatch,
  } = props;

  let initialValues = null;
  const msalInstance = azureAuth2.useMsalInstance();

  // Forces the page to rerender as refresh is Math.random().  Fixes the issue if page already loaded, but
  // user clicks on icon in header.  If no icon in header, remove refresh as a dependency.
  useEffect(() => {
    window.scrollTo(0, 0);
    clearManagerPreviousEmployeesDispatch();
    managerEmployeesDispatch({ msalInstance });
    // eslint-disable-next-line
  }, [refresh]);

  const renderTable = () => (
    <div style={{ paddingTop: '20px' }}>
      <Table
        hidePagination
        data-test={'results-table'}
          // data={formatRecords()}
        data={formatRecords(managerPreviousEmployeesSelector, true, null, null)}
        columns={[
          { dataField: 'id', hidden: true, text: '' },
          { dataField: 'name', text: 'Full Name', sort: true },
          { dataField: 'email', text: 'Email' },
          { dataField: 'serviceNow', text: 'ServiceNow' },
          { dataField: 'peopleSoft', text: 'PeopleSoft' },
          { dataField: 'storage', text: 'Storage' },
          { dataField: 'dateAuthorized', text: 'Date Authorized', sort: true },
        ]}
      />
    </div>
  );

  if (managerPreviousEmployeesSelector && managerPreviousEmployeesSelector.length > 0) {
    if (managerPreviousEmployeesSelector && managerPreviousEmployeesSelector.length > 0) {
      const authorizedApps = {};

      managerPreviousEmployeesSelector.forEach((record) => {
        const app1 = [];
        const app2 = [];
        const app3 = [];
        const app4 = [];

        if (record.app1 === 1) {
          app1.push('selected');
        }
        if (record.app2 === 1) {
          app2.push('selected');
        }
        if (record.app3 === 1) {
          app3.push('selected');
        }
        if (record.app4 === 1) {
          app4.push('selected');
        }

        authorizedApps[record.id] = { ...authorizedApps[record.id], app1 };
        authorizedApps[record.id] = { ...authorizedApps[record.id], app2 };
        authorizedApps[record.id] = { ...authorizedApps[record.id], app3 };
        authorizedApps[record.id] = { ...authorizedApps[record.id], app4 };

        initialValues = { ...initialValues, ...authorizedApps };
      });
    }
  }

  if (!managerPreviousEmployeesSelector) {
    return (
      <div className={'spinner'} />
    );
  }

  if (managerPreviousEmployeesSelector && managerPreviousEmployeesSelector.status && managerPreviousEmployeesSelector.status === 'loading') {
    return (
      <div className={'spinner'} />
    );
  }

  if (managerPreviousEmployeesSelector && managerPreviousEmployeesSelector.status >= 300) {
    return (
      <Oops />
    );
  }

  return (
    <ReduxForm
      form={'previousStaffForm'}
      initialValues={initialValues}
      onSubmit={'onSubmit'}
    >
      {renderTable()}
    </ReduxForm>
  );
};

PreviousStaffContent.propTypes = {
  refresh: T.number.isRequired,
  managerPreviousEmployeesSelector: T.oneOfType([
    T.array,
    T.object,
  ]),
  managerEmployeesDispatch: T.func.isRequired,
  clearManagerPreviousEmployeesDispatch: T.func.isRequired,
};

PreviousStaffContent.defaultProps = {
  managerPreviousEmployeesSelector: [],
};

// Name all selector variables ending with "Selector".  This makes it easier to distingush between
// selector and other variables
export const mapStateToProps = () => createStructuredSelector({
  managerPreviousEmployeesSelector: selectors.getManagerPreviousEmployees(),
});

// Name all dispatch variables ending with "Dispatch".  This makes it easier to distingush between
// dispatch and other variables
export const mapDispatchToProps = (dispatch) => ({
  managerEmployeesDispatch: (state) => dispatch(actions.getManagerEmployees(state)),
  clearManagerPreviousEmployeesDispatch: () => dispatch(actions.clearManagerPreviousEmployees()),
});

const usingRouter = withRouter(PreviousStaffContent);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
