import React, { useState } from 'react';
import { Navigation, MobileControls, Branding, SettingsBar, UtilityHeader, azureAuth2 } from 'state-template';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import T from 'prop-types';
import * as selectors from '../../redux/selectors';
import { HeaderRoutes } from '../../routes';

const Header = (props) => {
  const {
    authenticationMode,
    authSelector,
    isAuthenticated,
  } = props;

  const { MSAL, AUTHENTICATION_TYPES, ENVIRONMENT } = window.config;

  const title = '';
  const routes = HeaderRoutes(authSelector);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleSettingsOpen = () => {
    if (isSettingsOpen === true) {
      setIsSettingsOpen(false);
    } else {
      setIsSettingsOpen(true);
    }
  };

  const toggleMobileOpen = () => {
    if (isMobileOpen === true) {
      setIsMobileOpen(false);
    } else {
      setIsMobileOpen(true);
    }
  };

  const brandingProps = {
    src: '/images/logo.png',
    alt: 'React State Template',
  };

  const contactLink = {
    text: '',
    href: 'https://cdt.ca.gov/support/',
  };

  const displayEnvironment = () => {
    if (ENVIRONMENT) {
      if (ENVIRONMENT.toUpperCase() === 'LOCALHOST') {
        return (
          <div style={{ backgroundColor: 'goldenrod', textAlign: 'center', fontSize: 'xx-large', color: 'white' }}>{`${ENVIRONMENT.toUpperCase()}`}</div>
        );
      }
      if (ENVIRONMENT.toUpperCase() === 'DEVELOPMENT') {
        return (
          <div style={{ backgroundColor: 'blueviolet', textAlign: 'center', fontSize: 'xx-large', color: 'white' }}>{`${ENVIRONMENT.toUpperCase()}`}</div>
        );
      }
      if (ENVIRONMENT.toUpperCase() === 'TEST') {
        return (
          <div style={{ backgroundColor: 'darkgreen', textAlign: 'center', fontSize: 'xx-large', color: 'white' }}>{`${ENVIRONMENT.toUpperCase()}`}</div>
        );
      }
    }

    return null;
  };

  const settingsChildren = () => {
    if (authenticationMode === AUTHENTICATION_TYPES.MSAL) {
      return (
        isAuthenticated
          ? (
            <div>
              Welcome,
              {' '}
              {authSelector ? authSelector.givenName : null}
              {' '}
              {authSelector ? authSelector.surname : null}
              {' '}
              <img className={'img-responsive'} style={{ marginLeft: '7px', marginBottom: '5px', borderRadius: '50%', height: '30px', width: '30px' }} src={authSelector.profilePicture} alt={'profile'} />
              <azureAuth2.SignOutButton />
            </div>
          )
          : (
            <azureAuth2.SignInButton
              msal={MSAL}
            />
          ));
    }

    return null;
  };

  return (
    <header
      style={{ position: 'fixed' }}
      className={'global-header right'}
    >
      {displayEnvironment()}
      <UtilityHeader
        settingsChildren={settingsChildren()}
        toggleSettingsOpen={toggleSettingsOpen}
        contactLink={contactLink}
        title={title}
      />
      <SettingsBar
        toggleSettingsOpen={toggleSettingsOpen}
        isSettingsOpen={isSettingsOpen}
      />
      <Branding {...brandingProps} />
      <div className={'navigation-search'}>
        <MobileControls
          routes={routes}
          toggleMobileOpen={toggleMobileOpen}
        />
        <Navigation
          isMobileOpen={isMobileOpen}
          toggleMobileOpen={toggleMobileOpen}
          routes={routes}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  authSelector: T.object,
  authenticationMode: T.string,
};

Header.defaultProps = {
  authSelector: null,
  authenticationMode: null,
};

export const mapStateToProps = createStructuredSelector({
  authSelector: selectors.getAuth(),
});

export default connect(mapStateToProps, null)(Header);
