import React, { useState } from 'react';
import T from 'prop-types';
import { Button } from 'state-template';

export const DownloadButton = (props) => {
  const {
    auth,
    url,
    icon,
    className,
    text,
    fileName,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const showIcon = () => {
    let prop = {};
    if (isLoading === false) {
      prop = {
        name: icon,
      };
      return (
        prop
      );
    }

    return prop;
  };

  const downloadFile = async () => {
    setIsLoading(true);
    const headers = { Authorization: `Bearer ${auth.appAccessToken}` };

    const options = {
      headers,
    };

    await fetch(url, options).then((res) => res.blob())
      .then((blob) => {
        const url2 = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url2;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      });
  };

  return (
    <Button
      iconProps={showIcon()}
      className={className}
      onClick={downloadFile}
      disabled={isLoading}
    >
      {isLoading && (
        <i
          className={'fa fa-refresh fa-spin'}
          style={{ marginRight: '5px' }}
        />
      )}
      {isLoading && <span>Downloading ...</span>}
      {!isLoading && <span>{text}</span>}
    </Button>
  );
};

DownloadButton.propTypes = {
  auth: T.object.isRequired,
  url: T.string.isRequired,
  text: T.string.isRequired,
  icon: T.string.isRequired,
  className: T.string.isRequired,
  fileName: T.string.isRequired,
};

export default DownloadButton;
