import React from 'react';
import AuthHomePageContent from './components/AuthHomePageContent';

const HelpPage = () => (
  <>
    <AuthHomePageContent
      refresh={Math.random()}
    />
  </>
);

export default HelpPage;
