import React, { useState, useEffect } from 'react';
import { Form as ReduxForm, Button, ButtonRow, Table, azureAuth2 } from 'state-template';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import T from 'prop-types';

import Chart from 'react-apexcharts';
import formatRecords from '../../../utils/formatRecords';
import DownloadButton from '../../../components/Form/DownloadButton';
import Oops from '../../../components/Oops';

export const ReportsContent = (props) => {
  const {
    refresh,
    reportDispatch,
    reportSelector,
    authSelector,
    clearReportDispatch,
  } = props;

  // const REPORT_STATES

  const [countState, setCountState] = useState({ all: 0, approved: 0, denied: 0, pending: 0, noManager: 0 });
  const [seriesState, setSeriesState] = useState();
  const [recordState, setRecordState] = useState(reportSelector);
  const [reportTypeState, setReportTypeState] = useState('all');

  const { APP_API } = window.config;

  const msalInstance = azureAuth2.useMsalInstance();
  const fullYear = new Date().getFullYear();
  let initialValues = null;
  let tempTitle = null;

  const data = {
    options: {
      chart: {
      },
      dataLabels: {
        enabled: true,
      },
      labels: ['Approved', 'Denied', 'Pending', 'No Manager'],
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            legend: {
              position: 'bottom',
            },
            chart: {
              width: 500,
              height: 500,
            },
          },
        },
        {
          breakpoint: 510,
          options: {
            legend: {
              position: 'bottom',
            },
            chart: {
              width: 400,
              height: 400,
            },
          },
        },
        {
          breakpoint: 390,
          options: {
            legend: {
              position: 'bottom',
            },
            chart: {
              width: 300,
              height: 300,
            },
          },
        },
      ],
    },
  };

  // Called when component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line
    }, []);

  // Forces the page to rerender as refresh is Math.random().  Fixes the issue if page already loaded, but
  // user clicks on icon in header.  If no icon in header, remove this useEffect.
  useEffect(() => {
    clearReportDispatch();
    const queryParameters = `?Statuses=active&Year=${fullYear}`;
    reportDispatch({ msalInstance, queryParameters });

    // eslint-disable-next-line
    }, [refresh]);

  // Called when component is mounted
  useEffect(() => {
    setRecordState(reportSelector);
    if (reportSelector) {
      const series = [];
      series.push(reportSelector.approved);
      series.push(reportSelector.denied);
      series.push(reportSelector.pending);
      series.push(reportSelector.noManager);
      setSeriesState(series);

      setCountState({
        all: reportSelector ? reportSelector.all : 0,
        approved: reportSelector ? reportSelector.approved : 0,
        denied: reportSelector ? reportSelector.denied : 0,
        pending: reportSelector ? reportSelector.pending : 0,
        noManager: reportSelector ? reportSelector.noManager : 0,
      });
    }
  }, [reportSelector]);

  const iconProps = {
    caret: {
      name: 'caret-fill-down',
    },
    csv: {
      name: 'file-csv',
    },
  };

  const renderTable = () => {
    if (recordState === null) {
      return (
        <div className={'spinner-fixed'} />
      );
    }

    return (
      <div style={{ paddingTop: '20px' }}>
        <Table
          title={tempTitle}
          data-test={'results-table'}
          data={formatRecords(recordState ? recordState.items : [], true, null, null)}
          columns={[
            { dataField: 'id', hidden: true, text: '' },
            { dataField: 'name', text: 'Full Name', sort: true },
            { dataField: 'email', text: 'Email' },
            { dataField: 'serviceNow', text: 'ServiceNow' },
            { dataField: 'peopleSoft', text: 'PeopleSoft' },
            { dataField: 'storage', text: 'Storage' },
            { dataField: 'manager', text: 'Manager', sort: true },
            { dataField: 'dateAuthorized', text: 'Date Authorized', sort: true },
          ]}
        />
      </div>
    );
  };

  const report = (reportType) => {
    let queryParameters = `?year=${fullYear}`;

    setReportTypeState(reportType);

    if (reportType === 'approved') {
      queryParameters += '&status=approved';
    } else if (reportType === 'denied') {
      queryParameters += '&status=denied';
    } else if (reportType === 'pending') {
      queryParameters += '&status=pending';
    } else if (reportType === 'noManager') {
      queryParameters += '&status=nomanager';
    }

    setRecordState(null);
    reportDispatch({ msalInstance, queryParameters });
  };

  if (!reportSelector) {
    return (
      <div className={'spinner'} />
    );
  }

  if ((reportSelector.status === undefined || reportSelector.status === 'loading') && seriesState && seriesState[0] === undefined) {
    return (
      <div className={'spinner'} />
    );
  }

  if (reportSelector && reportSelector.status >= 300) {
    return (
      <Oops />
    );
  }

  if (reportSelector && reportSelector !== -1 && reportSelector.items && reportSelector.items.length > 0) {
    const authorizedApps = {};

    reportSelector.items.forEach((record) => {
      const app1 = [];
      const app2 = [];
      const app3 = [];
      const app4 = [];

      if (record.app1 === 1) {
        app1.push('selected');
      }
      if (record.app2 === 1) {
        app2.push('selected');
      }
      if (record.app3 === 1) {
        app3.push('selected');
      }
      if (record.app4 === 1) {
        app4.push('selected');
      }

      authorizedApps[record.id] = { ...authorizedApps[record.id], app1 };
      authorizedApps[record.id] = { ...authorizedApps[record.id], app2 };
      authorizedApps[record.id] = { ...authorizedApps[record.id], app3 };
      authorizedApps[record.id] = { ...authorizedApps[record.id], app4 };

      initialValues = { ...initialValues, ...authorizedApps };
    });
  }

  if (reportTypeState === 'all') {
    tempTitle = `All Employees (${countState.all})`;
  } else if (reportTypeState === 'approved') {
    tempTitle = `Approved Employees (${countState.approved})`;
  } else if (reportTypeState === 'denied') {
    tempTitle = `Denied Employees (${countState.denied})`;
  } else if (reportTypeState === 'pending') {
    tempTitle = `Pending Employees (${countState.pending})`;
  } else if (reportTypeState === 'noManager') {
    tempTitle = `Employees without Manager (${countState.noManager})`;
  }

  const typeDisplay = () => {
    if (reportTypeState === 'all') {
      return 'All';
    }
    if (reportTypeState === 'approved') {
      return 'Approved';
    }
    if (reportTypeState === 'denied') {
      return 'Denied';
    }
    if (reportTypeState === 'pending') {
      return 'Pending';
    }
    if (reportTypeState === 'noManager') {
      return 'No Manager';
    }

    return '';
  };

  if (!seriesState) {
    return (
      <div className={'spinner'} />
    );
  }

  return (
    <ReduxForm
      form={'reports'}
      onSubmit={'report'}
      initialValues={initialValues}
    >
      <div className={'d-flex justify-content-center'} style={{ margin: 'auto' }}>
        <Chart options={data.options} series={seriesState} type={'pie'} width={450} height={450} />
      </div>

      <p style={{ textAlign: 'center', paddingTop: '10px' }}>Click a button below to see all or filtered list of employees or download a report.</p>

      <div className={'d-flex justify-content-center'} style={{ margin: 'auto' }}>
        <ButtonRow>
          <Button
            iconProps={iconProps.caret}
            text={`All (${countState.all})`}
            className={'btn-custom-purple-solid'}
            onClick={() => report('all')}
          />
          <Button
            iconProps={iconProps.caret}
            text={`Approved (${countState.approved})`}
            className={'btn-custom-blue-solid'}
            onClick={() => report('approved')}
          />
          <Button
            iconProps={iconProps.caret}
            text={`Denied (${countState.denied})`}
            className={'btn-custom-green-solid'}
            onClick={() => report('denied')}
          />
          <Button
            iconProps={iconProps.caret}
            text={`Pending (${countState.pending})`}
            className={'btn-custom-yellow-solid'}
            onClick={() => report('pending')}
          />
          <Button
            iconProps={iconProps.caret}
            text={`No Manager (${countState.noManager})`}
            className={'btn-custom-red-solid'}
            onClick={() => report('noManager')}
          />
        </ButtonRow>
      </div>
      <div className={'d-flex justify-content-center'} style={{ margin: 'auto' }}>
        <ButtonRow>
          <DownloadButton
            icon={'file-csv'}
            className={'btn-custom-grey-solid'}
            text={`Download ${fullYear} (${typeDisplay()})`}
            auth={authSelector}
            url={`${APP_API}/paas/download${reportTypeState === 'all' ? '' : `?Status=${reportTypeState}`}`}
            fileName={`PAS-${fullYear}-${reportTypeState}.csv`}
          />
          <DownloadButton
            icon={'file-csv'}
            className={'btn-custom-grey-solid'}
            text={'Download Audit'}
            auth={authSelector}
            url={`${APP_API}/paas/download/audit`}
            fileName={'PAS-Audit.csv'}
          />
        </ButtonRow>
      </div>
      <hr />
      {renderTable()}
    </ReduxForm>
  );
};

ReportsContent.propTypes = {
  refresh: T.number.isRequired,
  reportDispatch: T.func.isRequired,
  reportSelector: T.object,
  authSelector: T.object,
  clearReportDispatch: T.func.isRequired,
};

ReportsContent.defaultProps = {
  reportSelector: null,
  authSelector: null,
};

// Name all selector variables ending with "Selector".  This makes it easier to distingush between
// selector and other variables
export const mapStateToProps = createStructuredSelector({
  reportSelector: selectors.getReport(),
  authSelector: selectors.getAuth(),
});

// Name all dispatch variables ending with "Dispatch".  This makes it easier to distingush between
// dispatch and other variables
export const mapDispatchToProps = (dispatch) => ({
  reportDispatch: (state) => dispatch(actions.getReport(state)),
  clearReportDispatch: (state) => dispatch(actions.clearReport(state)),
});

const usingRouter = withRouter(ReportsContent);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
