import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Table, Form as ReduxForm, Button, ButtonRow, azureAuth2 } from 'state-template';
import { withRouter, Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import T from 'prop-types';
import { reset, getFormValues, getFormInitialValues, change } from 'redux-form';
import Oops from '../../../components/Oops';

import formatRecords from '../../../utils/formatRecords';
import SuccessDialog from '../../../components/SuccessDialog/SuccessDialog';
import ErrorDialog from '../../../components/ErrorDialog';

const { APPS } = window.config;

export const CurrentStaffContent = (props) => {
  const {
    formValuesSelector,
    resetFormDispatch,
    refresh,
    updateFieldDispatch,
    managerEmployeesDispatch,
    managerCurrentEmployeesSelector,
    postCurrentStaffDispatch,
    postCurrentStaffSelector,
    resetPostCurrentStaffDispatch,
    clearManagerCurrentEmployeesDispatch,
  } = props;

  const [disableButtonState, setDisableButtonState] = useState(true);
  const [hideButtonsState, setHideButtonsState] = useState(true);
  const [compareValuesState, setCompareValuesState] = useState(undefined);

  let initialValues = null;
  const msalInstance = azureAuth2.useMsalInstance();

  // Forces the page to rerender as refresh is Math.random().  Fixes the issue if page already loaded, but
  // user clicks on icon in header.  If no icon in header, remove refresh as a dependency.
  useEffect(() => {
    resetFormDispatch();
    window.scrollTo(0, 0);
    clearManagerCurrentEmployeesDispatch();
    managerEmployeesDispatch({ msalInstance });
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (_.isEqual(formValuesSelector, compareValuesState)) {
      setDisableButtonState(true);
    } else {
      setDisableButtonState(false);
    }
    // eslint-disable-next-line
  }, [formValuesSelector]);

  useEffect(() => {
    if (managerCurrentEmployeesSelector && managerCurrentEmployeesSelector.length > 0) {
      setHideButtonsState(false);
    } else {
      setHideButtonsState(true);
    }
  }, [managerCurrentEmployeesSelector]);

  const selectAll = (id) => {
    updateFieldDispatch('permissionsForm', `${id}.${APPS.EMAIL}`, 'selected');
    updateFieldDispatch('permissionsForm', `${id}.${APPS.SERVICE_NOW}`, 'selected');
    updateFieldDispatch('permissionsForm', `${id}.${APPS.PEOPLE_SOFT}`, 'selected');
    updateFieldDispatch('permissionsForm', `${id}.${APPS.STORAGE}`, 'selected');
  };

  const unselectAll = (id) => {
    updateFieldDispatch('permissionsForm', `${id}.${APPS.EMAIL}`, null);
    updateFieldDispatch('permissionsForm', `${id}.${APPS.SERVICE_NOW}`, null);
    updateFieldDispatch('permissionsForm', `${id}.${APPS.PEOPLE_SOFT}`, null);
    updateFieldDispatch('permissionsForm', `${id}.${APPS.STORAGE}`, null);
  };

  const resetAll = () => {
    resetFormDispatch();
  };

  const resetPostCurrentStaff = () => {
    resetPostCurrentStaffDispatch();
    if (_.isEqual(formValuesSelector, compareValuesState)) {
      setDisableButtonState(true);
    } else {
      setDisableButtonState(false);
    }
  };
  const renderTable = () => (
    <div style={{ paddingTop: '20px' }}>
      <Table
        hidePagination
        data-test={'results-table'}
        data={formatRecords(managerCurrentEmployeesSelector, false, selectAll, unselectAll)}
        columns={[
          { dataField: 'id', hidden: true, text: '' },
          { dataField: 'name', text: 'Full Name', sort: true },
          { dataField: 'email', text: 'Email' },
          { dataField: 'serviceNow', text: 'ServiceNow' },
          { dataField: 'peopleSoft', text: 'PeopleSoft' },
          { dataField: 'storage', text: 'Storage' },
          { dataField: 'dateAuthorized', text: 'Date Authorized', sort: true },
          { dataField: 'actions', text: 'Actions' },
        ]}
      />
    </div>
  );

  const onSubmit = () => {
    const updateRecords = [];

    const guids = Object.keys(formValuesSelector);

    guids.forEach((entry) => {
      const resources = formValuesSelector[entry];
      const resourceName = Object.keys(resources);
      const record = {};

      record.id = entry;
      record.app1 = 0;
      record.app2 = 0;
      record.app3 = 0;
      record.app4 = 0;

      resourceName.forEach((x) => {
        if (resources[x] && resources[x].length > 0) {
          if (x === APPS.EMAIL) {
            record.app1 = 1;
          }
          if (x === APPS.SERVICE_NOW) {
            record.app2 = 1;
          }
          if (x === APPS.PEOPLE_SOFT) {
            record.app3 = 1;
          } if (x === APPS.STORAGE) {
            record.app4 = 1;
          }
        }
      });
      updateRecords.push(record);
    });

    postCurrentStaffDispatch({ msalInstance, updateRecords });
  };

  if (managerCurrentEmployeesSelector && managerCurrentEmployeesSelector.length > 0) {
    const authorizedApps = {};

    managerCurrentEmployeesSelector.forEach((record) => {
      const app1 = [];
      const app2 = [];
      const app3 = [];
      const app4 = [];

      if (record.app1 === 1) {
        app1.push('selected');
      }
      if (record.app2 === 1) {
        app2.push('selected');
      }
      if (record.app3 === 1) {
        app3.push('selected');
      }
      if (record.app4 === 1) {
        app4.push('selected');
      }

      authorizedApps[record.id] = { ...authorizedApps[record.id], app1 };
      authorizedApps[record.id] = { ...authorizedApps[record.id], app2 };
      authorizedApps[record.id] = { ...authorizedApps[record.id], app3 };
      authorizedApps[record.id] = { ...authorizedApps[record.id], app4 };

      initialValues = { ...initialValues, ...authorizedApps };
    });

    //   // Set compare values only on first render
    if (compareValuesState === undefined) {
      setCompareValuesState({ ...initialValues, ...authorizedApps });
    }
    // }
  }

  const checkAllValues = () => {
    if (formValuesSelector !== null && formValuesSelector !== undefined) {
      const guids = Object.keys(formValuesSelector);
      guids.forEach((entry) => {
        updateFieldDispatch('permissionsForm', `${[entry]}.app1`, ['selected']);
        updateFieldDispatch('permissionsForm', `${[entry]}.app2`, ['selected']);
        updateFieldDispatch('permissionsForm', `${[entry]}.app3`, ['selected']);
        updateFieldDispatch('permissionsForm', `${[entry]}.app4`, ['selected']);
      });
    }
  };

  const uncheckAllValues = () => {
    if (formValuesSelector !== null && formValuesSelector !== undefined) {
      const guids = Object.keys(formValuesSelector);
      guids.forEach((entry) => {
        updateFieldDispatch('permissionsForm', `${[entry]}.app1`, []);
        updateFieldDispatch('permissionsForm', `${[entry]}.app2`, []);
        updateFieldDispatch('permissionsForm', `${[entry]}.app3`, []);
        updateFieldDispatch('permissionsForm', `${[entry]}.app4`, []);
      });
    }
  };

  const displayButtons = () => {
    if (hideButtonsState === false) {
      return (
        <div className={'row'}>
          <div className={'col-md-6'}>
            <div style={{ textAlign: 'left' }}>
              <ButtonRow>
                <Button
                  text={'Check All'}
                  className={'btn-outline-primary'}
                  onClick={checkAllValues}
                />
                <Button
                  text={'Uncheck All'}
                  className={'btn-outline-secondary'}
                  onClick={uncheckAllValues}
                />
                <Button
                  text={'Reset All Changes'}
                  className={'btn-outline-danger'}
                  onClick={resetAll}
                  disabled={disableButtonState}
                />
              </ButtonRow>
            </div>
          </div>
          <div className={'col-md-6'}>
            <div style={{ textAlign: 'right' }}>
              <Button
                type={'submit'}
                text={'Save All'}
                className={'btn-outline-primary'}
                disabled={disableButtonState}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const displayResult = () => {
    if (postCurrentStaffSelector !== null) {
      if (postCurrentStaffSelector) {
        if (postCurrentStaffSelector === 'SUCCESS') {
          if (!_.isEqual(compareValuesState, formValuesSelector)) {
            setCompareValuesState(formValuesSelector);
          }
          const success = {};
          success.title = 'Success';
          success.status = 'Successful Submission';
          success.statusText = 'The request has been successfully submitted';
          success.icon = 'check-fill';
          success.iconColor = 'green';
          return (
            <SuccessDialog
              success={success}
              handleReset={resetPostCurrentStaff}
            />
          );
        }
      }
      if (postCurrentStaffSelector.status !== 204) {
        const err = [];
        if (postCurrentStaffSelector.errorMessage) {
          const keys = Object.keys(postCurrentStaffSelector.errorMessage.errors);
          keys.forEach((key) => {
            err.push(`${key}: ${postCurrentStaffSelector.errorMessage.errors[key]}`);
          });
        }
        const error = {};
        error.title = 'Unable to Submit Request';
        error.status = 'Unable to submit request';
        error.statusText = err;
        error.icon = 'warning-triangle';
        error.iconColor = '#b8291f';

        return (
          <ErrorDialog
            error={error}
            resetData={resetPostCurrentStaff}
          />
        );
      }
    }

    return null;
  };

  if (!managerCurrentEmployeesSelector) {
    return (
      <div className={'spinner'} />
    );
  }

  if (managerCurrentEmployeesSelector && managerCurrentEmployeesSelector.status && managerCurrentEmployeesSelector.status === 'loading') {
    return (
      <div className={'spinner'} />
    );
  }

  if (managerCurrentEmployeesSelector && managerCurrentEmployeesSelector.status >= 300) {
    return (
      <Oops />
    );
  }

  return (
    <ReduxForm
      form={'permissionsForm'}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <Prompt
        when={!_.isEqual(formValuesSelector, compareValuesState)}
        message={'You are currently editing the request. Your changes will be lost if you leave.'}
      />
      {displayResult()}
      {renderTable()}
      {displayButtons()}

    </ReduxForm>
  );
};

CurrentStaffContent.propTypes = {
  formValuesSelector: T.object,
  resetFormDispatch: T.func.isRequired,
  refresh: T.number.isRequired,
  updateFieldDispatch: T.func.isRequired,
  managerEmployeesDispatch: T.func.isRequired,
  postCurrentStaffSelector: T.string,
  managerCurrentEmployeesSelector: T.oneOfType([
    T.array,
    T.object,
  ]),
  postCurrentStaffDispatch: T.func.isRequired,
  resetPostCurrentStaffDispatch: T.func.isRequired,
  clearManagerCurrentEmployeesDispatch: T.func.isRequired,
};

CurrentStaffContent.defaultProps = {
  formValuesSelector: undefined,
  postCurrentStaffSelector: null,
  managerCurrentEmployeesSelector: [],
};

// Name all selector variables ending with "Selector".  This makes it easier to distingush between
// selector and other variables
export const mapStateToProps = () => createStructuredSelector({
  formValuesSelector: getFormValues('permissionsForm'),
  formInitialValuesSelector: getFormInitialValues('permissionsForm'),
  managerCurrentEmployeesSelector: selectors.getManagerCurrentEmployees(),
  postCurrentStaffSelector: selectors.postCurrentStaff(),
});

// Name all dispatch variables ending with "Dispatch".  This makes it easier to distingush between
// dispatch and other variables
export const mapDispatchToProps = (dispatch) => ({
  resetFormDispatch: () => dispatch(reset('permissionsForm')),
  updateFieldDispatch: (form, field, newValue) => dispatch(change(form, field, newValue)),
  managerEmployeesDispatch: (state) => dispatch(actions.getManagerEmployees(state)),
  exampleDataDispatch: () => dispatch(actions.getExampleData()),
  postCurrentStaffDispatch: (values) => dispatch(actions.postCurrentStaff(values)),
  resetPostCurrentStaffDispatch: () => dispatch(actions.postCurrentStaffSuccess(null)),
  clearManagerCurrentEmployeesDispatch: () => dispatch(actions.clearManagerCurrentEmployees()),
});

const usingRouter = withRouter(CurrentStaffContent);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
