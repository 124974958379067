import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { Icon, Button } from 'state-template';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

export const ErrorDialog = (props) => {
  const {
    error,
    resetData,
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (error !== undefined) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = () => {
    setOpen(false);

    resetData();
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'lg'}
        style={{ zIndex: 3000 }}
        id={'dialogId'}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >

        <DialogTitle
          id={'scroll-dialog-title'}
        >
          <Icon
            name={error.icon}
            srOnly={'Warning'}
            style={{ paddingRight: '10px', color: error.iconColor }}
          />
          {error.title}
        </DialogTitle>
        <DialogContent dividers>
          Error Status -
          {' '}
          {`${error.status}: ${error.statusText}`}
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            {error.messages}
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              variant={'primary'}
              onClick={handleClose}
              name={'close'}
            >
              Close
            </Button>
          </div>

        </DialogContent>

      </Dialog>
    </>
  );
};

ErrorDialog.propTypes = {
  error: T.object,
  resetData: T.func.isRequired,
};

ErrorDialog.defaultProps = {
  error: {},
};

export default ErrorDialog;
