import React, { useEffect } from 'react';
import { Page, Button } from 'state-template';
import { Link } from 'react-router-dom';

import { headerPadding } from '../../utils/environment';

const BackToTopLink = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Button
      onClick={scrollToTop}
      className={'btn-secondary'}
    >
      Back to top
    </Button>
  );
};

const HelpPage = () => {
  // Called when component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={headerPadding()}>
      <Page title={'Help'}>
        <div>
          <ul>
            <li><a href={'#manageStaff'}>Managing Staff Authorizations</a></li>
            <li><a href={'#viewReports'}>Viewing Reports</a></li>
            <li><a href={'#assignTemporaryManagers'}>Assigning Temporary Managers</a></li>
            <li><a href={'#submitIssue'}>Submitting an Issue</a></li>
          </ul>
        </div>
        <hr />

        <div id={'manageStaff'}>
          <h2>Managing Staff Authorizations</h2>
          <p>
            Authorizations for current employees can be viewed and edited on the
            {' '}
            <Link to={'/'}>Current Staff Authorizations</Link>
            {' '}
            page. Only managers have access to this page. Authorizations marked yellow indicate that the employee has neither been approved nor denied authorization and action is required. When authorizing employees marked yellow, all applications must be approved or denied before submitting.
          </p>
          <p>An employees authorizations can be changed to denied or approved by following the following steps:</p>
          <ol>
            <li>
              Change the authorization to
              {' '}
              <strong>Yes</strong>
              {' '}
              or
              {' '}
              <strong>No</strong>
              {' '}
              for each application by clicking:
            </li>
            <ul>
              <li>A. The applications toggle button for individual applications.</li>
              <li>
                B. The
                {' '}
                <strong>All</strong>
                {' '}
                button for all applications. Use
                {' '}
                <strong>None</strong>
                {' '}
                button to deselect all applications.
              </li>
            </ul>
            <li>
              Click
              {' '}
              <strong>Save All</strong>
              {' '}
              to finalize any changes made.
            </li>
          </ol>
          <img src={'/images/paas-current-staff-help.png'} alt={'Current staff controls explanation'} />
          <p>
            Authorizations for previous employees can be viewed on the
            {' '}
            <Link to={'/previousStaff'}>Previous Staff Authorizations</Link>
            {' '}
            page. These authorizations are for historical purposes only, and cannot be edited.
          </p>
          <BackToTopLink />
          <hr />
        </div>

        <div id={'viewReports'}>
          <h2>Viewing Reports</h2>
          <p>
            Reports for current and previous staff authorizations can be viewed on the
            {' '}
            <Link to={'/report'}>Reports</Link>
            {' '}
            page.
          </p>
          <ol>
            <li>
              Select filters such as the
              {' '}
              <strong>Authorization Year</strong>
              {' '}
              to display only authorizations that match the given filter.
            </li>
            <li>Toggle between showing all staff and directly reportable staff (HR and Security managers only).</li>
            <li>
              Click
              {' '}
              <strong>Download CSV</strong>
              {' '}
              to export a csv file of the currently visible data based off selected filters.
            </li>
            <li>
              Click
              {' '}
              <strong>Download Audit</strong>
              {' '}
              to export a csv file of all historical data based off selected filters (Only HR and Security).
            </li>
            <li>
              Select
              {' '}
              <strong>All</strong>
              ,
              {' '}
              <strong>All Approved</strong>
              ,
              {' '}
              <strong>All Denied</strong>
              ,
              {' '}
              <strong>Pending</strong>
              , or
              {' '}
              <strong>No Manager</strong>
              {' '}
              (HR only) in the pie chart legend to toggle the list of authorizations being displayed.
            </li>
          </ol>
          <img src={'/images/paas-view-reports-help.png'} alt={'Reports controls explanation'} />
          <br />
          <BackToTopLink />
          <hr />
        </div>

        <div id={'assignTemporaryManagers'}>
          <h2>Assigning Temporary Managers</h2>
          <p>
            Employees without managers can be temporarily assigned one using the
            {' '}
            <Link to={'/admin'}>Admin</Link>
            {' '}
            page. Only HR has access to this page.
          </p>
          <p>To temporarily assign a manager to an employee:</p>
          <ol>
            <li>
              Select the employee from the
              {' '}
              <strong>No Manager</strong>
              {' '}
              list (or from the
              {' '}
              <strong>Assigned Manager</strong>
              {' '}
              list for employees that already have a manager assigned).
            </li>
            <img src={'/images/assign-temp-manager-help-1.png'} alt={'No Manager list'} />

            <li>
              Select the temporary manager from the
              {' '}
              <strong>Manager</strong>
              {' '}
              dropdown.
            </li>
            <li>
              Click
              {' '}
              <strong>Submit</strong>
              {' '}
              to finish.
            </li>
            <img src={'/images/assign-temp-manager-help-2.png'} alt={'Submit temporary manager'} />
          </ol>
          <BackToTopLink />
          <hr />
        </div>

        <div id={'submitIssue'}>
          <h2>Submitting an Issue</h2>
          <p>
            For any issues with this application, please contact the
            {' '}
            <a href={'https://cdt.ca.gov/support/'} target={'_blank'} rel={'noopener noreferrer'}>Service Desk</a>
            {' '}
            and ask to have a work order routed to the
            {' '}
            <strong>Applications Support / Business Process Automation Support</strong>
            {' '}
            group.
          </p>
          <p>
            For quicker assistance with an issue, please include the applications name (
            <strong>PAS</strong>
            ) and details about the issue in the work order.
          </p>
          <BackToTopLink />
        </div>

      </Page>
    </div>
  );
};

export default HelpPage;
