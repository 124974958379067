import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
import { withAsync, api, azureAuth2 } from 'state-template';
import jwt_decode from 'jwt-decode';
import * as actions from './actions';
import * as C from './constants';

const { APP_API, API_SCOPES, EXAMPLE_API, AUTHENTICATION_TYPES } = window.config;

export function* getExampleData() {
  const url = `${EXAMPLE_API}/todos`;
  try {
    const data = yield call(api.request, url);
    yield put(actions.getExampleDataSuccess(data));
  } catch (error) {
    yield put(actions.getExampleDataSuccess(error));
  }
}

export function* clearManagerCurrentEmployees() {
  yield put(actions.getManagerCurrentEmployeesSuccess({ status: 'loading' }));
}

export function* clearManagerPreviousEmployees() {
  yield put(actions.getManagerPreviousEmployeesSuccess({ status: 'loading' }));
}

export function* clearHasNoManager() {
  yield put(actions.getHasNoManagerSuccess({ status: 'loading' }));
}

export function* getManagerEmployees(input) {
  const previousEmployees = [];
  const currentEmployees = [];

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${APP_API}/paas/auth`;
    const data = yield api.request(url, params);

    data.forEach(((record) => {
      if (record.status === 'active' || record.status === 'assignedManager') {
        currentEmployees.push(record);
      } else {
        previousEmployees.push(record);
      }
    }));

    yield put(actions.getManagerPreviousEmployeesSuccess(previousEmployees));
    yield put(actions.getManagerCurrentEmployeesSuccess(currentEmployees));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getManagerEmployees()', error);
    yield put(actions.getManagerCurrentEmployeesSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
    yield put(actions.getManagerPreviousEmployeesSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* clearManagers() {
  yield put(actions.getManagersSuccess({ status: 'loading' }));
}

export function* getManagers(input) {
  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${APP_API}/paas/manager`;
    const data = yield api.request(url, params);
    yield put(actions.getManagersSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getManagers()', error);
    yield put(actions.getManagersSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getHasNoManager(input) {
  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${APP_API}/paas/hasnomanager`;
    const data = yield api.request(url, params);
    yield put(actions.getHasNoManagerSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getHasNoManager()', error);
    yield put(actions.getHasNoManagerSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* clearReport() {
  yield put(actions.getReportSuccess({ status: 'loading' }));
}

export function* getReport(input) {
  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${APP_API}/paas/reports${input.payload.queryParameters}`;
    const data = yield api.request(url, params);

    yield put(actions.getReportSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    //  console.log('getReport()', error);
    yield put(actions.getReportSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getAuth(input) {
  yield put(actions.getAuthSuccess(input));
}

export function* appMsalToken(input) {
  const { apiScopes, msalConfig } = input.payload;

  const app = {
    scopes: apiScopes,
    account: msalConfig.msalInstance.accounts[0],
  };

  let accessToken = null;

  yield msalConfig.msalInstance.instance.acquireTokenSilent(app).then((response) => {
    accessToken = response.accessToken;
  }).catch((e) => {
    msalConfig.msalInstance.instance.acquireTokenSilent(app).then((response) => {
      accessToken = response.accessToken;
    });
  });

  return accessToken;
}

export function* initApp(input) {
  const { authenticationMode, msalConfig } = input.payload;

  if (authenticationMode === AUTHENTICATION_TYPES.MSAL) {
    let graphData = null;
    let appAccessToken = null;

    if (msalConfig.msalInstance.isAuthenticated) {
      graphData = yield azureAuth2.callMsGraph(msalConfig);
      appAccessToken = yield appMsalToken(input);

      if (graphData) {
        graphData.isAuthenticated = true;
        graphData.appAccessToken = appAccessToken;

        let { roles } = jwt_decode(appAccessToken);

        if (!roles) {
          roles = [];
        }

        graphData.roles = roles;
        yield getAuth(graphData);
      }
    }
  }
}

export function* postCurrentStaff(input) {
  try {
    let params = {};
    let url = null;
    url = `${APP_API}/paas/auth`;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'POST', API_SCOPES);
    params.body = JSON.stringify(input.payload.updateRecords);
    yield call(api.request, url, params);
    yield put(actions.postCurrentStaffSuccess('SUCCESS'));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('postCurrentStaff()', error);
    yield put(actions.postCurrentStaffSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* postAssignTemporaryManager(input) {
  try {
    let params = {};
    let url = null;
    url = `${APP_API}/paas/manager`;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'POST', API_SCOPES);
    params.body = JSON.stringify(input.payload.record);
    yield api.request(url, params);

    yield put(actions.postAssignTemporaryManagerSuccess('SUCCESS'));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('postAssignTemporaryManager()', error)
    yield put(actions.postAssignTemporaryManagerSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export default function* exampleSaga() {
  yield all([
    takeEvery(C.GET_EXAMPLE_DATA, withAsync(getExampleData)),
    takeEvery(C.INIT_APP, withAsync(initApp)),
    takeEvery(C.GET_AUTH, withAsync(getAuth)),
    takeEvery(C.POST_CURRENT_STAFF, withAsync(postCurrentStaff)),
    takeEvery(C.POST_ASSIGN_TEMPORARY_MANAGER, withAsync(postAssignTemporaryManager)),
    takeEvery(C.GET_MANAGER_EMPLOYEES, withAsync(getManagerEmployees)),
    takeEvery(C.CLEAR_MANAGER_CURRENT_EMPLOYEES, withAsync(clearManagerCurrentEmployees)),
    takeEvery(C.CLEAR_MANAGER_PREVIOUS_EMPLOYEES, withAsync(clearManagerPreviousEmployees)),
    takeEvery(C.GET_MANAGERS, withAsync(getManagers)),
    takeEvery(C.CLEAR_MANAGERS, withAsync(clearManagers)),
    takeEvery(C.GET_HAS_NO_MANAGER, withAsync(getHasNoManager)),
    takeEvery(C.CLEAR_HAS_NO_MANAGER, withAsync(clearHasNoManager)),
    takeEvery(C.GET_REPORT, withAsync(getReport)),
    takeEvery(C.CLEAR_REPORT, withAsync(clearReport)),
  ]);
}
