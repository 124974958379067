import React, { useEffect } from 'react';
import { Page } from 'state-template';
import { headerPadding } from '../../utils/environment';

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={headerPadding()}>
      <Page>
        <div className={'centered'}>

          <div style={{ fontSize: '100px' }}><strong>404</strong></div>
          <div>
            <img alt={'Completed'} style={{ height: '100px', width: '100px', marginTop: '10px', marginBottom: '20px' }} src={'images/404.png'} />
          </div>
          Sorry, the page you requested cannot be found
        </div>
      </Page>
    </div>
  );
};

export default NotFoundPage;
