import React from 'react';
import { Page } from 'state-template';
import { headerPadding } from '../../utils/environment';
import AdminContent from './components/AdminContent';

export const AdminPage = () => (
  <div className={headerPadding()}>
    <Page title={'Administration'}>
      <AdminContent
        // Prop (refresh) forces the page to rerender as the prop will always change.  Fixes issue if
        // page is already mounted and icon (in header) is clicked again.  Might be helpful if form
        // calls an API to get data and the data changes.  Otherwise, remove "refresh" property.
        refresh={Math.random()}
      />
    </Page>
  </div>
);

export default AdminPage;
