import React from 'react';
import T from 'prop-types';
import { FieldCheckboxes, ButtonRow, Button } from 'state-template';

const { APPS } = window.config;

export const EmailCheckBox = (employee) => (
  <div style={{ paddingTop: '10px', textAlign: 'center' }}>
    <FieldCheckboxes
      style={{ fontSize: '20px' }}
      name={`${employee.record.id}.${APPS.EMAIL}`}
      disabled={employee.disabled}
      label={''}
      options={
        [
          { value: 'selected', label: '' },
        ]
      }
    />
  </div>
);

export const ServiceNowCheckBox = (employee) => (
  <div style={{ paddingTop: '10px', textAlign: 'center' }}>
    <FieldCheckboxes
      style={{ fontSize: '20px' }}
      name={`${employee.record.id}.${APPS.SERVICE_NOW}`}
      disabled={employee.disabled}
      label={''}
      options={
        [
          { value: 'selected', label: '' },
        ]
      }
    />
  </div>
);

export const PeopleSoftCheckBox = (employee) => (
  <div style={{ paddingTop: '10px', textAlign: 'center' }}>
    <FieldCheckboxes
      style={{ fontSize: '20px' }}
      name={`${employee.record.id}.${APPS.PEOPLE_SOFT}`}
      disabled={employee.disabled}
      label={''}
      options={
        [
          { value: 'selected', label: '' },
        ]
      }
    />
  </div>
);

export const StorageCheckBox = (employee) => (
  <div style={{ paddingTop: '10px', textAlign: 'center' }}>
    <FieldCheckboxes
      style={{ fontSize: '20px' }}
      name={`${employee.record.id}.${APPS.STORAGE}`}
      disabled={employee.disabled}
      label={''}
      options={
        [
          { value: 'selected', label: '' },
        ]
      }
    />
  </div>
);

export const Actions = (props) => {
  const {
    selectAll,
    unselectAll,
  } = props;

  return (
    <ButtonRow>
      <Button
        className={'btn-sm btn-outline-primary'}
        onClick={selectAll}
      >
        All
      </Button>
      <Button
        className={'btn-sm btn-outline-secondary'}
        onClick={unselectAll}
      >
        None
      </Button>
    </ButtonRow>
  );
};

Actions.propTypes = {
  selectAll: T.func.isRequired,
  unselectAll: T.func.isRequired,
};
