import React, { useEffect } from 'react';
import { Page } from 'state-template';
import { envHomeHeader } from '../../utils/environment';

const banner = (
  <div
    className={'headerTopPadding header-decoration hidden-print text-center section-primary'}
    style={{
      backgroundImage: 'url(/images/background-primary.jpg)',
      backgroundSize: 'cover',
    }}
  >
    <div className={'text-center'}>
      <div className={'row'}>
        <div className={'half offset-quarter'}>
          <div className={'m-b-lg'}>
            <h1 className={'display m-b'}>
              <font style={{ color: '#ffffff' }}>PAS</font>
              <br />
              <font style={{ color: '#ffffff' }}>Personnel Authorization System</font>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Called when component is mounted
export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={envHomeHeader()}>
      {banner}

      <Page>
        <div className={'headerTopPaddinghome'} style={{ textAlign: 'center' }}>
          <h2>Personnel Authorization System</h2>
        </div>
        <p style={{ textAlign: 'center' }}>
          The Personnel Authorization System grants access to applications and resources within the California Department of Technology
        </p>
      </Page>

    </div>
  );
};

export default HomePage;
