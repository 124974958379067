import React from 'react';
import { azureAuth2 } from 'state-template';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ErrorBoundary from './ErrorBoundary';
import AppMsal from './components/AppMsal';
import AppNoAuth from './components/AppNoAuth';

const App = () => {
  const { AUTHENTICATION_METHOD, AUTHENTICATION_TYPES, MSAL } = window.config;

  const app = () => {
    if (AUTHENTICATION_METHOD === AUTHENTICATION_TYPES.MSAL) {
      const publicClientApplication = new PublicClientApplication(azureAuth2.msalConfig(MSAL));
      return (
        <>
          <MsalProvider instance={publicClientApplication}>
            <AppMsal />
          </MsalProvider>
        </>
      );
    }

    return <AppNoAuth />;
  };

  return (
    <ErrorBoundary>
      {app()}
    </ErrorBoundary>
  );
};

export default App;
