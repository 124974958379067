import React, { useEffect } from 'react';
import { Page } from 'state-template';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from 'redux/selectors';
import { headerPadding } from '../../../utils/environment';

export const AuthHomePageContent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={headerPadding()}>
      <Page>
        <div style={{ paddingTop: '10px', textAlign: 'center' }}>
          <h2>
            Welcome to the
            <br />
            Personnel Authorization System
            <br />
            (PAS)
          </h2>
        </div>
        <p>
          All managers and supervisors with direct reports are required to track access for email, ServiceNow, PeopleSoft and storage of their direct reports.
          This is a mandatory task to comply with our State Administration Manual.
        </p>
      </Page>
    </div>
  );
};

export const mapStateToProps = createStructuredSelector({
  authSelector: selectors.getAuth(),
});

const usingRouter = withRouter(AuthHomePageContent);
const usingRedux = connect(mapStateToProps, null)(usingRouter);
export default usingRedux;
