import { createSelector } from 'reselect';

export const selectDomain = (state) => state.reducer;
export const selectExampleData = (state) => selectDomain(state).exampleData;
export const selectLastFetched = (state) => selectDomain(state).lastFetched;
export const selectAuth = (state) => selectDomain(state).auth;
export const selectGetAuth = (state) => selectDomain(state).getAuth;
export const selectManagerCurrentEmployees = (state) => selectDomain(state).managerCurrentEmployees;
export const selectManagerPreviousEmployees = (state) => selectDomain(state).managerPreviousEmployees;
export const selectManagers = (state) => selectDomain(state).managers;
export const selectHasNoManager = (state) => selectDomain(state).hasNoManager;
export const selectPostAssignTemporaryManager = (state) => selectDomain(state).postAssignTemporaryManager;
export const selectPostCurrentStaff = (state) => selectDomain(state).postCurrentStaff;
export const selectReport = (state) => selectDomain(state).report;

export const getExampleData = () => createSelector(
  selectExampleData,
  (exampleData) => exampleData,
);

export const getLastFetched = () => createSelector(
  selectLastFetched,
  (lastFetched) => lastFetched,
);

export const getAuth = () => createSelector(
  selectAuth,
  (auth) => auth,
);

export const getManagerCurrentEmployees = () => createSelector(
  selectManagerCurrentEmployees,
  (managerCurrentEmployees) => managerCurrentEmployees,
);

export const getManagerPreviousEmployees = () => createSelector(
  selectManagerPreviousEmployees,
  (managerPreviousEmployees) => managerPreviousEmployees,
);

export const getManagers = () => createSelector(
  selectManagers,
  (managers) => managers,
);

export const getHasNoManager = () => createSelector(
  selectHasNoManager,
  (hasNoManager) => hasNoManager,
);

export const postCurrentStaff = () => createSelector(
  selectPostCurrentStaff,
  (request) => request,
);

export const postAssignTemporaryManager = () => createSelector(
  selectPostAssignTemporaryManager,
  (request) => request,
);

export const getReport = () => createSelector(
  selectReport,
  (report) => report,
);
