import React from 'react';
import { EmailCheckBox, ServiceNowCheckBox, PeopleSoftCheckBox, StorageCheckBox, Actions } from '../components/Form';

export const formatRecords = (employees, disabled, selectAll, unselectAll) => {
  const formattedEmployees = [];

  if (employees && employees.length > 0) {
    employees.forEach((record) => {
      const formattedRecord = {};

      formattedRecord.id = record.id;
      formattedRecord.name = record.fullName;
      formattedRecord.manager = record.managerFullName;
      formattedRecord.email = <EmailCheckBox record={record} disabled={disabled} />;
      formattedRecord.serviceNow = <ServiceNowCheckBox record={record} disabled={disabled} />;
      formattedRecord.peopleSoft = <PeopleSoftCheckBox record={record} disabled={disabled} />;
      formattedRecord.storage = <StorageCheckBox record={record} disabled={disabled} />;
      formattedRecord.dateAuthorized = record.lastApproved ? record.lastApproved.split('T')[0] : null;
      formattedRecord.actions = <Actions selectAll={() => selectAll(record.id)} unselectAll={() => unselectAll(record.id)} />;

      formattedEmployees.push(formattedRecord);
    });
    // for (const [, record] of employees.entries()) {
    //   const formattedRecord = {};

    //   formattedRecord.id = record.id;
    //   formattedRecord.name = record.fullName;
    //   formattedRecord.manager = record.managerFullName;
    //   formattedRecord.email = <EmailCheckBox record={record} disabled={disabled} />;
    //   formattedRecord.serviceNow = <ServiceNowCheckBox record={record} disabled={disabled} />;
    //   formattedRecord.peopleSoft = <PeopleSoftCheckBox record={record} disabled={disabled} />;
    //   formattedRecord.storage = <StorageCheckBox record={record} disabled={disabled} />;
    //   formattedRecord.dateAuthorized = record.lastApproved ? record.lastApproved.split('T')[0] : null;
    //   formattedRecord.actions = <Actions selectAll={() => selectAll(record.id)} unselectAll={() => unselectAll(record.id)} />;

    //   formattedEmployees.push(formattedRecord);
    // }
  }

  return (formattedEmployees);
};

export default formatRecords;
