const schema = {
  employee: {
    required: true,
    name: 'employee',
    label: 'Employee',
    placeholder: 'Choose Employee',
  },
  manager: {
    required: true,
    name: 'manager',
    label: 'Manager',
    placeholder: 'Choose Manager',
  },
};

export default schema;
