import HomePage from 'containers/HomePage';
import HelpPage from 'containers/HelpPage';
import CurrentStaffPage from 'containers/CurrentStaffPage';
import PreviousStaffPage from 'containers/PreviousStaffPage';
import AdminPage from 'containers/AdminPage';
import AuthHomePage from 'containers/AuthHomePage';
import ReportsPage from 'containers/ReportsPage';
import NotFoundPage from 'containers/NotFoundPage';

const { ROLES } = window.config;

export const HeaderRoutes = (auth) => {
  const routes = [];
  const authHomePage = {
    key: 'AuthHomePage',
    name: 'Home',
    path: '/home',
    icon: 'ca-gov-icon-home',
  };

  const homePage = {
    key: 'home',
    name: 'Home',
    path: '/',
    icon: 'ca-gov-icon-home',
  };

  const currentStaffPage = {
    key: 'currentStaff',
    name: 'Current Staff',
    path: '/current_staff',
    icon: 'ca-gov-icon-users',
  };

  const previousStaffPage = {
    key: 'previousStaff',
    name: 'Previous Staff',
    path: '/previous_staff',
    icon: 'ca-gov-icon-close-fill',
  };

  const adminPage = {
    key: 'admin',
    name: 'Admin',
    path: '/admin',
    icon: 'ca-gov-icon-gears',
  };

  const reportsPage = {
    key: 'reports',
    name: 'Reports',
    path: '/reports',
    icon: 'ca-gov-icon-document',
  };

  const helpPage = {
    key: 'help',
    name: 'Help',
    path: '/help',
    icon: 'ca-gov-icon-question-line',
  };

  // This control which icons(s) a user sees.  Very helpful if developing an application which has roles.
  if (auth && auth.isAuthenticated) {
    routes.push(authHomePage);
    routes.push(currentStaffPage);
    routes.push(previousStaffPage);
    if (auth.roles) {
      if (auth.roles.includes(ROLES.MANAGER) || auth.roles.includes(ROLES.SYSTEM_ADMINISTRATOR)) {
        routes.push(adminPage);
      }
      if (auth.roles.includes(ROLES.HR)) {
        routes.push(reportsPage);
      }
    }
    routes.push(helpPage);
  } else {
    routes.push(homePage);
  }

  return routes;
};

export const Routes = (auth) => {
  const routes = [];

  const homePage = {
    key: 'home',
    name: 'Home',
    exact: true,
    path: '/',
    component: HomePage,
    hidden: true,
  };

  const authHomePage = {
    key: 'authHomePage',
    exact: true,
    path: '/home',
    component: AuthHomePage,
    hidden: true,
  };

  const currentStaffPage = {
    key: 'currentStaff',
    name: 'Current Staff',
    exact: true,
    path: '/current_staff',
    component: CurrentStaffPage,
    hidden: true,
  };

  const previousStaffPage = {
    key: 'previousStaff',
    name: 'Previous Staff',
    exact: true,
    path: '/previous_staff',
    component: PreviousStaffPage,
    hidden: true,
  };

  const adminPage = {
    key: 'adminStaff',
    name: 'Admin',
    exact: true,
    path: '/admin',
    component: AdminPage,
    hidden: true,
  };

  const reportsPage = {
    key: 'reports',
    name: 'Reports',
    exact: true,
    path: '/reports',
    component: ReportsPage,
    hidden: true,
  };

  const helpPage = {
    key: 'help',
    name: 'Help',
    exact: true,
    path: '/help',
    component: HelpPage,
    hidden: true,
  };

  const notFoundPage = {
    key: 'notFound',
    name: 'Not Found',
    exact: true,
    path: '/not-found',
    component: NotFoundPage,
    hidden: true,
  };

  // This control which pages a user has accsss to.  Very helpful if developing an application which has roles.
  if (auth && auth.isAuthenticated) {
    routes.push(authHomePage);
    routes.push(currentStaffPage);
    routes.push(previousStaffPage);
    if (auth.roles) {
      if (auth.roles.includes(ROLES.MANAGER) || auth.roles.includes(ROLES.SYSTEM_ADMINISTRATOR)) {
        routes.push(adminPage);
      }
      if (auth.roles.includes(ROLES.HR)) {
        routes.push(reportsPage);
      }
    }
    routes.push(helpPage);
  } else {
    routes.push(homePage);
  }

  routes.push(notFoundPage);

  return routes;
};
